import React from "react";

const SentenceStatus = ({ sentenceStatus, currentKuralIndex }) => {
  const getSentenceStatusColor = (status, index) => {
    switch (status) {
      case "correct":
        return "bg-gradient-to-r from-lime-400 to-lime-500";
      case "incorrect":
        return "bg-gradient-to-r from-red-500 to-orange-500";
      case "current":
        return "bg-gradient-to-r from-amber-200 to-yellow-400";
      default:
        return "bg-gray-300";
    }
  };

  return (
    <div className="flex space-x-2 bg-gray-500 p-2 rounded-lg mb-2">
      {sentenceStatus.map((status, index) => (
        <div
          key={index}
          className={`w-4 h-4 rounded-full ${
            index === currentKuralIndex
              ? getSentenceStatusColor("current", index)
              : getSentenceStatusColor(status, index)
          }`}
        ></div>
      ))}
    </div>
  );
};

export default SentenceStatus;
