import React, { useState, useEffect, useCallback } from "react";
import SelectionCard from "./components/selectionCard";
import SentenceStatus from "./components/SentenceStatus";
import ShuffledWordPanel from "./components/ShuffledWordPanel";
import ArrangedWordPanel from "./components/ArrangedWordPanel";
import Timer from "./components/Timer";
import { motion } from "framer-motion"; // Importing motion for animation
import "./App.css";

function App() {
  const [selectedKurals, setSelectedKurals] = useState([]); // State to hold the 10 random kurals
  const [currentKuralIndex, setCurrentKuralIndex] = useState(0); // To track which kural is being shown
  const [shuffledWords, setShuffledWords] = useState([]);
  const [configData, setConfigData] = useState(null);
  const [userAnswer, setUserAnswer] = useState([]);
  const [score, setScore] = useState({ correctSentences: 0, correctWords: 0 });
  const [filterChapters, setFilterChapters] = useState(null); // State to store filter chapters
  const [gameStarted, setGameStarted] = useState(false); // Track if the game has started
  const [gameOver, setGameOver] = useState(false); // Track if the game is over
  const [sentenceStatus, setSentenceStatus] = useState([]); // New state to track sentence status
  const [timeLimit, setTimeLimit] = useState(5); // Configurable time limit (default is 5 minutes)
  const [remainingTime, setRemainingTime] = useState(timeLimit * 60); // Remaining time in seconds
  const [TestTitle, setTestTitle] = useState(""); // State to hold the 10 random kurals

  // Load sounds for correct and incorrect actions
  const correctSound = new Audio("/sounds/correct.mp3");
  const incorrectSound = new Audio("/sounds/incorrect.mp3");

  // Function to filter the Kurals by chapters
  const filterKuralsByChapter = useCallback((kuralArray, chapters) => {
    return kuralArray.filter((kural) => chapters.includes(kural.chapter));
  }, []);

  // Function to randomly shuffle and select 10 records from the filtered list
  const selectRandomKurals = useCallback((kuralArray, numRecords) => {
    const shuffled = shuffleArray([...kuralArray]);
    return shuffled.slice(0, numRecords);
  }, []);

  // Function to start a new game (show a new kural)
  const startNewGame = useCallback((kuralSet, index) => {
    if (index >= kuralSet.length) {
      setGameOver(true); // Mark the game as over if all sentences are completed
      return;
    }

    const selectedKural = kuralSet[index];
    const concatenatedKural = selectedKural.kural.join(" ");

    // Split the sentence into words and remove periods using .replace()
    const words = concatenatedKural
      .split(" ")
      .map((word) => word.replace(".", ""));

    setCurrentKuralIndex(index);
    setShuffledWords(shuffleArray([...words]));
    setUserAnswer([]);
  }, []);

  // Function to fetch data and start the game
  const handleStartGame = (no, athikaram, time, titile) => {
    console.log(`Number, ${no}`);
    console.log(`Athikaram, ${athikaram}`);
    console.log(`Time, ${time}`);
    console.log(`title, ${titile}`);
    setTestTitle(titile);
    const selectedChapters = athikaram;
    setFilterChapters(selectedChapters);
    setGameStarted(true);
    setGameOver(false);
    setRemainingTime(time * 60);
    setTimeLimit(time);
    fetch("/thirukural_git.json")
      .then((response) => response.json())
      .then((data) => {
        const filteredKurals = filterKuralsByChapter(
          data.kurals,
          selectedChapters
        );

        const selectedKuralSet = selectRandomKurals(filteredKurals, no);
        setSelectedKurals(selectedKuralSet);
        setSentenceStatus(new Array(no).fill("unanswered"));
        startNewGame(selectedKuralSet, 0);
      })
      .catch((error) => console.error("Error loading JSON data:", error));
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleWordClick = (word, index) => {
    // Add clicked word to the userAnswer array
    const updatedUserAnswer = [...userAnswer, word];
    setUserAnswer(updatedUserAnswer);

    console.log("filterChapters", filterChapters);
    // Remove the clicked word by replacing it with null
    setShuffledWords((prevWords) =>
      prevWords.map((w, i) => (i === index ? null : w))
    );

    if (selectedKurals.length && selectedKurals[currentKuralIndex]) {
      const correctWords = selectedKurals[currentKuralIndex].kural
        .join(" ")
        .split(" ")
        .map((word) => word.replace(".", ""));
      const isCorrectWord = correctWords[updatedUserAnswer.length - 1] === word;

      if (isCorrectWord) {
        correctSound.play();
      } else {
        incorrectSound.play();
      }

      // Check if all words are arranged, pass updatedUserAnswer to checkAnswer
      if (updatedUserAnswer.length === correctWords.length) {
        checkAnswer(updatedUserAnswer); // Pass the updatedUserAnswer directly
      }
    }
  };

  const checkAnswer = useCallback(
    (updatedUserAnswer) => {
      if (!selectedKurals.length || !selectedKurals[currentKuralIndex]) {
        return;
      }

      const correctWords = selectedKurals[currentKuralIndex].kural
        .join(" ")
        .split(" ")
        .map((word) => word.replace(".", ""));
      const isCorrectSentence =
        updatedUserAnswer.join(" ") === correctWords.join(" ");

      let correctWordCount = 0;
      updatedUserAnswer.forEach((word, index) => {
        if (word === correctWords[index]) {
          correctWordCount++;
        }
      });

      // Update sentence status
      setSentenceStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentKuralIndex] = isCorrectSentence
          ? "correct"
          : "incorrect";
        return newStatus;
      });

      // Update the score
      setScore((prevScore) => ({
        correctSentences:
          prevScore.correctSentences + (isCorrectSentence ? 1 : 0),
        correctWords: prevScore.correctWords + correctWordCount,
      }));

      // Move to the next sentence
      //startNewGame(selectedKurals, currentKuralIndex + 1);
      // Automatically move to the next Kural or end the game if all sentences are completed
      setTimeout(() => {
        startNewGame(selectedKurals, currentKuralIndex + 1); // Start the next Kural after a short delay
      }, 1000); // 1-second delay to let user see the result
    },
    [selectedKurals, currentKuralIndex, startNewGame]
  );

  useEffect(() => {
    if (gameStarted && !gameOver && remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (remainingTime === 0) {
      setGameOver(true);
    }
  }, [gameStarted, gameOver, remainingTime]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        const configData = data.config;
        console.log("configData", configData);
        setConfigData(configData);
      });
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const calculateTimeTaken = () => {
    const totalTime = timeLimit * 60;
    const timeTaken = totalTime - remainingTime;
    return formatTime(timeTaken);
  };

  const handleRestartGame = () => {
    setGameStarted(false);
    setGameOver(false);
    setSelectedKurals([]);
    setCurrentKuralIndex(0);
    setSentenceStatus([]);
    setScore({ correctSentences: 0, correctWords: 0 });
    setRemainingTime(timeLimit * 60);
  };

  return (
    <div className="flex justify-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-700  ">
      <div className="shadow-lg  w-full max-w-full  ">
        <div className="min-h-full">
          <div className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 p-1 text-center  text-white">
            <h3 className="text-4xl font-bold tracking-widest">
              {"அரசு உதவி பெறும் ஆரம்பப்பள்ளி - குப்பகுடி"}
            </h3>
            <div className="font-semibold text-gray-300 p-1">
              புதுக்கோட்டை மாவட்டம் - திருவரங்குளம் ஒன்றியம்
            </div>
          </div>

          <div>
            <h3 className="text-4xl text-white  text-center font-bold tracking-widest pt-5">
              {"குறளாட்டம் கொண்டாட்டம்"}
            </h3>
            <h3 className="text-lg text-gray-400  text-center tracking-widest pt-1">
              {"Sponsored by Vetri Academy Kuppakudi"}
            </h3>
            <p className="text-gray-300 text-center">நன்றி : vilaiyaatu.com</p>
          </div>

          {/* Configurable time limit input */}
          {!gameStarted && !gameOver && (
            <div className="text-center mb-4">
              <div className="hidden">
                <label className="mr-2">Set Time Limit (Minutes):</label>
                <input
                  type="number"
                  value={timeLimit}
                  onChange={(e) => setTimeLimit(Number(e.target.value))}
                  className="border px-2 py-1 rounded bg-gray-800 text-white"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6 p-4">
                {configData &&
                  configData.map((config, index) => (
                    <div key={index}>
                      <SelectionCard
                        handleClick={handleStartGame}
                        no={config.no}
                        time={config.time}
                        titile={config.titile}
                        athikaram={config.athikaram}
                        gradient={config.gradient}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* Display the game only if it has started */}
          {gameStarted && !gameOver && (
            <>
              {/* Panel 1: Shuffled Words with Fixed Position and Removed Words */}
              <div className="rounded-lg border border-blue-300 bg-gradient-to-r from-blue-200 to-cyan-200 p-6 m-4  min-h-52">
                <div className="mb-4 flex justify-between items-center relative w-full">
                  {/* Left-aligned content */}
                  <div className="text-left">
                    <h2 className="text-xl font-bold">கலைக்கப்பட்ட குறள்</h2>{" "}
                    <h2 className="text-sm text-gray-70">{TestTitle}</h2>
                    {/* Example Left Content */}
                  </div>

                  {/* Left-aligned content */}
                  <div className="text-right sm:block lg:hidden">
                    <Timer time={formatTime(remainingTime)} />
                  </div>
                  {/* Center-aligned Sentence Status Indicator and Time Left */}
                  <div className="absolute inset-x-0 top-0 flex flex-col items-center ">
                    {/* Time Left Display */}

                    <div className="hidden lg:block">
                      <Timer time={formatTime(remainingTime)} />
                    </div>
                  </div>
                </div>

                <div className="mt-5 flex justify-between items-center ">
                  <ShuffledWordPanel
                    shuffledWords={shuffledWords}
                    handleWordClick={handleWordClick}
                  />
                </div>
                {/* Center-aligned Sentence Status Indicator and Time Left */}

                <div className=" inset-x-0 top-0 flex flex-col items-center mt-3">
                  <SentenceStatus
                    sentenceStatus={sentenceStatus}
                    currentKuralIndex={currentKuralIndex}
                  />
                </div>
              </div>

              {/* Panel 2: Arranged Words (User's Sentence) */}
              <div className="m-4  border border-indigo-700 min-h-60 p-6 bg-gradient-to-br from-blue-200 via-blue-400 to-blue-600 rounded-lg">
                <h2 className="text-xl font-semibold mb-2">
                  அடுக்கப்பட்ட குறள்
                </h2>
                <ArrangedWordPanel
                  userAnswer={userAnswer}
                  selectedKurals={selectedKurals}
                  currentKuralIndex={currentKuralIndex}
                />
              </div>

              {/* Panel 3: Score */}
            </>
          )}

          {/* Game Over and Restart */}
          {gameOver && (
            <div className="text-center">
              <div className="text-center">
                <h2 className="text-3xl text-yellow-200 font-semibold mb-4">
                  Game Over!
                </h2>
                <h2 className="text-2xl text-yellow-400 font-semibold mb-4">
                  Time's up or you've completed all sentences.
                </h2>
              </div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                <div className="w-96 border border-gray-300 rounded-lg shadow-md overflow-hidden mx-auto mt-8">
                  {/* Header */}
                  <div className="bg-gradient-to-r from-teal-400 to-yellow-200 text-white text-center py-2">
                    <h2 className="text-2xl text-black font-semibold">
                      Score Card
                    </h2>
                  </div>

                  {/* Body - List of scores */}

                  <div className="p-4">
                    <ul className="list-none m-0 p-0">
                      <li className="py-2 border-b last:border-b-0 border-gray-200 text-lg text-yellow-400 font-semibold ">
                        சரியாக அடுக்கப்பட்ட குறள்கள் : {score.correctSentences}
                      </li>
                      <li className="py-2 border-b last:border-b-0 border-gray-200 text-lg text-yellow-400 font-semibold">
                        சரியாக அடுக்கப்பட்ட சீர்கள் : {score.correctWords}
                      </li>
                      <li className="py-2 border-b last:border-b-0 border-gray-200 text-lg text-yellow-400 font-semibold">
                        எடுத்துக்கொண்ட நேரம் : {calculateTimeTaken()}
                      </li>
                      <li className="py-2 border-b last:border-b-0 border-gray-200 text-lg text-yellow-400 font-semibold">
                        மீத நேரம்: {formatTime(remainingTime)}
                      </li>
                    </ul>
                  </div>

                  {/* Footer - Button */}
                  <div className="bg-gray-50 text-center py-2">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={handleRestartGame}
                    >
                      Restart the Game
                    </button>
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
