import React from "react";
import { motion } from "framer-motion"; // Importing motion for animation
const ShuffledWordPanel = ({ shuffledWords, handleWordClick }) => {
  return (
    <div className="flex flex-col space-y-2 ">
      {/* First Line - First Four Words */}
      <div className="grid md:grid-cols-4 sm:grid-cols-2  gap-2">
        {shuffledWords.slice(0, 4).map((word, index) => (
          <motion.button
            key={index}
            className={`text-2xl tracking-widest  px-4 py-2 font-bold  bg-gradient-to-r from-fuchsia-500 to-pink-500 border-2 border-fuchsia-600 rounded text-white cursor-pointer  ${
              !word ? "invisible" : ""
            }`}
            onClick={() => handleWordClick(word, index)}
            whileTap={{ opacity: 0, scale: 0.5 }} // Dissolve effect on click
            transition={{ duration: 0.4 }} // Control animation speed for dissolve effect
            disabled={!word} // Disable if the word is null (removed)
          >
            {word || ""} {/* Display word or empty space */}
          </motion.button>
        ))}
      </div>
      {/* Second Line - Remaining Words */}
      <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-2 ">
        {shuffledWords.slice(4).map((word, index) => (
          <motion.button
            key={index + 4}
            className={`text-2xl tracking-widest  px-4 font-bold py-2 bg-gradient-to-r from-fuchsia-500 to-pink-500 bg-fuchsia-200 border-2 text-white border-fuchsia-600 rounded cursor-pointer ${
              !word ? "invisible" : ""
            }`}
            onClick={() => handleWordClick(word, index + 4)}
            whileTap={{ opacity: 0, scale: 0.5 }} // Dissolve effect on click
            transition={{ duration: 0.4 }} // Control animation speed for dissolve effect
            disabled={!word} // Disable if the word is null (removed)
          >
            {word || ""} {/* Display word or empty space */}
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default ShuffledWordPanel;
