import React from "react";

const Timer = ({ time }) => {
  return (
    <div className="text-white bg-red-500 rounded-lg border-2 border-red-900 px-1 ">
      <p className="text-2xl font-bold">{time}</p>
    </div>
  );
};

export default Timer;
