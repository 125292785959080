import React from "react";
import { motion } from "framer-motion"; // Importing motion for animation
const ArrangedWordPanel = ({
  userAnswer,
  selectedKurals,
  currentKuralIndex,
}) => {
  const getWordBgColor = (word, index) => {
    if (!selectedKurals.length || !selectedKurals[currentKuralIndex]) {
      return "bg-gray-300";
    }

    const correctWords = selectedKurals[currentKuralIndex].kural
      .join(" ")
      .split(" ")
      .map((word) => word.replace(".", ""));
    return word === correctWords[index]
      ? "bg-green-300 border-2 border-green-400 text-black bg-gradient-to-r from-lime-400 to-lime-500 font-bold"
      : "bg-red-300 border-2 border-red-400 text-black bg-gradient-to-r from-red-500 to-orange-500 font-bold";
  };

  return (
    <div className="flex flex-col space-y-2">
      {/* First Line - First Four Arranged Words */}
      <div className="flex flex-wrap gap-2">
        {userAnswer.slice(0, 4).map((word, index) => (
          <motion.span
            key={index}
            className={`text-2xl tracking-widest px-4 py-2 rounded  ${getWordBgColor(
              word,
              index
            )}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            {word}
          </motion.span>
        ))}
      </div>
      {/* Second Line - Remaining Arranged Words */}
      <div className="flex flex-wrap gap-2">
        {userAnswer.slice(4).map((word, index) => (
          <motion.span
            key={index + 4}
            className={`text-2xl tracking-widest px-4 py-2 rounded  ${getWordBgColor(
              word,
              index + 4
            )}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            {word}
          </motion.span>
        ))}
      </div>
    </div>
  );
};

export default ArrangedWordPanel;
