import React from "react";
import { motion } from "framer-motion"; // Importing motion for animation
function SelectionCard({ titile, no, time, athikaram, handleClick, gradient }) {
  return (
    <>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="flex flex-col border border-gray-700  text-gray-900 rounded-2xl ">
          <div
            className={`rounded-t-2xl p-2 text-center text-white ${gradient}`}
          >
            <h3 className="text-2xl  font-bold">{titile}</h3>
          </div>
          <div className="p-6 xl:py-9 xl:px-12 bg-indigo-50 ">
            <div className="grid grid-cols-2  gap-4 mb-6">
              <div>
                <h3 className="font-manrope text-xl text-left font-bold mb-2">
                  நேரம்
                </h3>
                <div className="flex items-center mb-6">
                  <span className="mr-2 text-4xl font-semibold text-indigo-600">
                    {time}
                  </span>
                  <span className="text-lg text-gray-500 ">நிமிடம்</span>
                </div>
              </div>
              <div>
                <h3 className="font-manrope text-lg text-left font-bold mb-2">
                  குறள்கள்
                </h3>
                <div className="flex items-center mb-6">
                  <span className=" mr-2 text-4xl font-semibold text-indigo-600">
                    {no}
                  </span>
                  <span className="text-xl text-gray-500 ">மட்டும்</span>
                </div>
              </div>
            </div>

            <button
              onClick={() => handleClick(no, athikaram, time, titile)}
              className="py-2.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit block mx-auto hover:bg-indigo-700"
            >
              Start the Game
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default SelectionCard;
